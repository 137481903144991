import axios from "../config/axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContextProvider";

const PinContext = createContext();

function PinContextProvider({ children }) {
  const [pin, setPin] = useState([]);
  const [pinById, setPinById] = useState([]);
  const [createdPin, setCreatedPin] = useState([]);
  // const [myCreate, setMyCreate] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const fetchPin = async () => {
    try {
      const res = await axios.get("/pin");
      setPin(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchMyRestaurants = async() => {
  //   const res = await axios.get('/restaurant/mycreated')
  //   // console.log(res.data.myCreatedRestaurants)
  //   setMyCreate(res.data.myCreatedRestaurants)
  // }

  // fetchMyRestaurants()

  useEffect(() => {
    fetchPin();
  }, []);

  useEffect(() => {
    fetchPin();
  }, [pinById]);

  useEffect(() => {
    fetchPin();
  }, [user.id]);

  const fetchPinById = async pinId => {
    try {
      setLoading(true);
      const res = await axios.get(`/pin/single?pinid=${pinId}`);
      setPinById(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchMyCreated = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/restaurant/mycreated");
      setCreatedPin(res.data.myCreatedRestaurants);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const createNewPin = async (name, restaurantId) => {
    try {
      setLoading(true);
      await axios.post("/pin", { name, restaurantId });
      fetchPin();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const savePinRes = async input => {
    try {
      setLoading(true);
      const res = await axios.patch("/pin/restaurant", input);
      console.log(res.data);
      fetchPin();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updatePin = async (name, pinId) => {
    try {
      setLoading(true);
      await axios.patch("/pin", { name, pinId });
      fetchPin();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deletePin = async pinId => {
    try {
      setLoading(true);
      await axios.delete(`/pin?pinId=` + pinId);
      fetchPin();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const removeRestaurant = async (restaurantId, pinId) => {
    try {
      setLoading(true);
      await axios.delete(
        `/pin/restaurant?restaurantId=${restaurantId}&pinId=${pinId}`
      );
      fetchPin();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PinContext.Provider
      value={{
        pin,
        pinById,
        createdPin,
        loading,
        setPin,
        createNewPin,
        fetchPin,
        fetchPinById,
        updatePin,
        deletePin,
        savePinRes,
        removeRestaurant,
        fetchMyCreated,
      }}
    >
      {children}
    </PinContext.Provider>
  );
}

const usePin = () => {
  const ctx = useContext(PinContext);
  return ctx;
};

export default PinContextProvider;

export { usePin };
