import ContainerAddMenusPage from "../components/AddMenusPage/ContainerAddMenusPage";

function AddMenuPage() {
   return (
      <div className = "addmenu-background">
         <ContainerAddMenusPage />
      </div>
   );
}
export default AddMenuPage;
